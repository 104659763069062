import { Datepicker } from 'vanillajs-datepicker';

import fr from 'vanillajs-datepicker/locales/fr';
Object.assign(Datepicker.locales, fr);

export class DatePickerCustom {
    constructor()
    {
        console.log('months')
        const months = document.querySelectorAll('input[data-month]')
        months.forEach(m => {
            new Datepicker(m, {
                pickLevel: 1,
                language: 'fr',
            })

            /** Au changement de date */
            m.addEventListener('changeDate', function (){
                console.log('changeDate')
                m.dispatchEvent(new CustomEvent('change', {bubbles:true}))
            })
        })
    }
}
