export class Header {
    constructor()
    {
        this.header = document.querySelector('body header.banner')
        if (this.header) {
            this.nav = document.querySelector('.nav')

            const burger = document.querySelector('#burger')
            if (burger) {
                burger.addEventListener('click', evt => {
                    evt.preventDefault()
                    burger.classList.toggle('active')
                    this.nav.classList.toggle('mobile')

                    console.log(this.nav)
                })
            }
        }
    }
}
