export class Archive {
    constructor()
    {
        this.container = document.querySelector('*[data-archive]')
        if (!this.container) {
            return;
        }
        this.type = this.container.getAttribute('data-archive')

        this.moreBtn = this.container.querySelector('*[data-archive-btn-more]');
        this.moreBtnInitText = this.moreBtn.innerHTML
        this.filters_container = this.container.querySelector('*[data-filters]');
        this.form = this.container.querySelector('form[data-filter-form]');
        this.filters_type = this.filters_container.getAttribute('data-filters');
        this.filterreset = this.filters_container.querySelectorAll('*[data-filter="*"]');
        this.filters = this.filters_container.querySelectorAll('*[data-filter]');
        this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
        this.init()
    }

    init()
    {
        this.config = this.defaults()
        this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/wp/v3/${this.type}`
        this.register()
    }

    defaults()
    {
        return {
            itemsperpage: parseInt(this.container.dataset.itemsperpage),
            itemsoffset: parseInt(this.container.dataset.itemsperpage),
            currentpage: 1,
            noMoreItems: false,
            currentTax: '',
        }
    }

    reset()
    {
        this.filters.forEach(f => f.classList.remove('active'))
        this.config = this.defaults()
        this.itemsContainer.innerHTML = "";
        this.moreBtn.innerHTML = this.moreBtnInitText;
        this.moreBtn.removeAttribute('hidden')
    }

    register()
    {
        if (this.form) {
            /** Event sur le form */
            this.form.addEventListener('change', evt => {
                evt.preventDefault();
                const target = evt.target;

                if (target.hasAttribute('data-filter')) {
                    this.filterreset.forEach(f => f.classList.remove('active'))
                }

                this.config.currentpage = 1;
                this.itemsContainer.innerHTML = "";
                this.appendSkeletons();
                this.fetchFilter(true);
            })
        }

        this.filterreset.forEach(f => {
            f.addEventListener('click', (e) => {
                e.preventDefault();
                const id = f.dataset.filter
                this.reset()
                this.filters.forEach(flt => flt.checked = false)
                this.form.dispatchEvent(new CustomEvent('change'))

                f.classList.add('active')
            })
        })

        this.moreBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.config.currentpage += 1;
            if (this.config.noMoreItems) {
                this.moreBtn.setAttribute('hidden', '')
                return;
            }

            this.fetchFilter();
        })
    }

    fetchFilter(reset = false)
    {
        const params = new URLSearchParams(new FormData(this.form));
        params.append('per_page', this.config.itemsperpage)
        params.append('page', this.config.currentpage)
        //console.log(this.config,params.toString())
        // params.append(this.filters_type, this.config.currentTax)

        fetch(`${this.url}?${params.toString()}`)
            .then((r) => r.json())
            .then(r => {
                if (reset) {
                    this.itemsContainer.innerHTML = "";
                }

                if (r.length === 0 || r.length < this.config.itemsperpage) {
                    this.moreBtn.innerHTML = 'Aucun résulat trouvés';
                    this.config.noMoreItems = true;
                    this.moreBtn.setAttribute('hidden', '')
                } else {
                    this.moreBtn.removeAttribute('hidden')
                }

                if (r.length === 0 && this.config.currentpage === 1) {
                    this.moreBtn.innerHTML = 'Aucun évènement';
                    this.config.noMoreItems = true;
                    this.moreBtn.removeAttribute('hidden')
                }

                this.appendItems(r);
            })
            .catch(r => {
                this.moreBtn.setAttribute('hidden', '')
                this.moreBtn.innerHTML = 'Aucun résulat trouvés';
                this.config.noMoreItems = true;
            })
    }

    appendSkeletons()
    {
        const template = document.querySelector('#skeleton');

        for (let i = 0; i < 3; i++) {
            let clone = template.content.cloneNode(true);
            this.itemsContainer.appendChild(clone)
        }
    }

    appendItems(items)
    {
        const template = document.querySelector('#archive-el');

        items.forEach((item) => {
            let clone = template.content.cloneNode(true);

            switch (this.type) {
                case 'lbffevent':
                    clone = this.itemEvent(clone, item)
                    break;
            }

            this.itemsContainer.appendChild(clone)
        })
    }

    itemEvent(clone, item)
    {
        const link = clone.querySelector('.lbffevent--link');

        const thumb = clone.querySelector('.lbffevent-thumb__bg');
        const title = clone.querySelector('.lbffevent__title');
        const cat = clone.querySelector('.lbffevent-cat');

        const date = clone.querySelector('.lbffevent-footer__date')
        const city = clone.querySelector('.lbffevent-footer__city')
        const startTime = clone.querySelector('.lbffevent-footer__startTime')
        const building = clone.querySelector('.lbffevent-footer__building')

        link.setAttribute('href', item.link)

        const thumbnail = document.createElement('img')
        thumbnail.setAttribute('src', item.thumbnail)
        thumb.appendChild(thumbnail)

        if(title) title.innerHTML = item.post_title
        if(date) date.innerHTML = item.acf.date
        if(city) city.innerHTML = item.acf.city
        if(startTime) startTime.innerHTML = item.acf.startTime
        if(building) building.innerHTML = item.acf.location

        if (item.cat) {
            const thumbnail_cat = document.createElement('img')
            thumbnail_cat.setAttribute('src', item.cat?.thumbnail)
            cat.appendChild(thumbnail_cat)
        }

        return clone
    }
}
