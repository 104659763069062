import '@css/main.scss';

import {Archive} from './components/Archive';
import {Header} from './components/Header';
import {DatePickerCustom} from './components/DatePickerCustom';

window.onload = () => {
    new Archive()
    new Header()
    new DatePickerCustom()
}
